import { Suspense, lazy, useEffect } from 'react'

import { BrowserRouter, Routes, Route } from "react-router";

import { RecoilRoot } from 'recoil';

import PrivateRoute from './components/PrivateRoute';

import './App.css';

import { QueryClient } from '@tanstack/react-query'
import { PersistQueryClientProvider } from '@tanstack/react-query-persist-client'
import { createSyncStoragePersister } from '@tanstack/query-sync-storage-persister'

import { ChakraProvider, defaultSystem } from "@chakra-ui/react"
import {system} from './theme';

import { Toaster } from "@/components/ui/toaster"

import { analytics } from './utils/firebase';
import { AuthProvider } from "./hooks/use-auth";
import { logEvent } from 'firebase/analytics';

import useNetwork from "./hooks/useNetwork";

import Loading from './pages/Loading'

import "@fontsource/roboto";

import AuthActions from './pages/auth/actions';
const ResetPassword = lazy(() => import('./pages/account/reset-password'));
const AccountRecover = lazy(() => import('./pages/account/recover'));
const TestError = lazy(() => import('./pages/TestError'));

const Login = lazy(() => import('./pages/Login'));

const Layout = lazy(() => import('./components/Layout/Layout'));

// Public Stat pagges
const Monitor = lazy(() => import('./pages/monitor/index'));
// semi private kiosk pages 
const Kiosk = lazy(() => import('./pages/kiosk/index'));

const NotAuthorized = lazy(() => import('./pages/not-authorized'));

const MINUTE = 1000 * 60;

const queryClient = new QueryClient({
   defaultOptions: {
     queries: {
       staleTime: 10 * MINUTE,
    //   gcTime: 10 * MINUTE
     },
   },
});
 
const persister = createSyncStoragePersister({
  storage: window.localStorage,
})

function App() {

  useEffect(() => {
    // Example: Log custom event when the app loads
    logEvent(analytics, 'page_view');
  }, []);

  return (
    <AuthProvider>
      <ChakraProvider value={system} >
        <Toaster />
            <RecoilRoot>

                <PersistQueryClientProvider
                  client={queryClient}
                  persistOptions={{ persister }}
                >
                  
                    <BrowserRouter>

                      <Suspense fallback={<Loading />}>
                        <Routes>

                          <Route path="/*" element={<PrivateRoute path="/*"  > <Layout/> </PrivateRoute>} />
                 
                          <Route path="/login" element={ <Login/>}  /> 

                          <Route path="/embed/multiclock/:eid" element={ <Monitor/>}  />
                          <Route path="/monitor/:eid" element={ <Monitor/>}  /> 
                          <Route path="/kiosk/:eid/*" element={ <Kiosk/>}  /> 

                          <Route path="/test-error" element={ <TestError/>}  /> 

                          <Route path="/auth/actions" element={ <AuthActions/>}  /> 
                          <Route path="/account/reset-password" element={ <ResetPassword/>}  />
                          <Route path="/account/recover" element={ <AccountRecover/>}  />
                          <Route path="/not-authorized" element={ <PrivateRoute path="/not-authorized"  > <NotAuthorized/> </PrivateRoute>}  />

                        </Routes>
                        
                      </Suspense>

                    </BrowserRouter>

                </PersistQueryClientProvider>
         
            </RecoilRoot>

      </ChakraProvider>

    </AuthProvider>
  
  );
}

export default App;